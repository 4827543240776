import React from "react";
import { Tabtitle } from "components/title/Title";

import NavLinkAdd from "components/NavLinks/NavLinkAdd";
import axiosusers from "utils/api/axiosusers";
import { Box, CircularProgress } from "@mui/material";
import TableOrders from "./Main/TableOrders";
import useSWR from "swr";
import SearchOrders from "./Main/SearchOrders";

const index = () => {
  Tabtitle("Đơn hàng");
  const data = JSON.parse(localStorage.getItem("auth"));

  const pageSize = 10;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [searchId, setSearchId] = React.useState("");
  const [searchSatus, setSearchStatus] = React.useState("");
  const [searchPaymentStatus, setSearchPaymentStatus] = React.useState("");
  const [searchGame, setSearchGame] = React.useState([]);
  const [searchClient, setSearchClient] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchDataAll = async () => {
    Promise.all([
      axiosusers.get("/orders/orders", {
        params: {
          productId: searchGame?.id,
          clientId: searchClient?.id,
          searchId,
          status: searchSatus,
          paymentStatus: searchPaymentStatus,
          pageSize,
          currentPage: currentPage === 0 ? 1 : currentPage,
          userId: data.id,
        },
      }),
    ])
      .then(async (results) => {
        const [res] = results.map((res) => res.data);
        setProduct(res.orders);
        setPageCount(Math.ceil(res.pages / pageSize));
      })
      .finally(() => setLoading(true))
      .catch((error) => console.error(error));
  };

  React.useEffect(() => {
    fetchDataAll();
  }, [
    pageSize,
    searchId,
    currentPage,
    searchGame,
    searchSatus,
    searchClient,
    searchPaymentStatus,
  ]);

  const handleCurrentPage = (newChecked) => {
    setCurrentPage(newChecked);
  };
  const onSearchGame = (data) => {
    setSearchGame(data);
  };

  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const fetchDataSwr2 = async (url) => {
    const response = await axiosusers.get(url[0], {
      params: {
        searchId: "",
        gameId: "",
        groupId: "",
        status: "",
        pageSize: 10,
        currentPage: 1,
        userId: url[1]?.id,
      },
    });
    return response.data;
  };
  const { data: gameData } = useSWR(`/products/products-all`, fetchDataSwr);
  const { data: clientData } = useSWR(`/clients/clients-all`, fetchDataSwr);
  const { data: userData } = useSWR(["/orders/orders", data], fetchDataSwr2);
  const { data: walletsData } = useSWR(`/wallets/wallets-all`, fetchDataSwr);
  const { data: danhsachbanData } = useSWR(
    `/danhsachban/danhsachban-all`,
    fetchDataSwr
  );
  const pageCounts =
    userData?.pages !== undefined && !loading
      ? Math.ceil(userData.pages / pageSize)
      : pageCount;
  const products =
    userData?.pages !== undefined && !loading ? userData?.orders : product;
  let danhsachbanDatas = [];
  if (danhsachbanData !== undefined) {
    danhsachbanDatas = danhsachbanData.filter((item) => item.status === false);
  }
  return (
    <div>
      <NavLinkAdd href="/manager/donhang/add" name="Tạo đơn hàng" />

      <SearchOrders
        onSearchId={(e) => setSearchId(e)}
        onSearchGame={onSearchGame}
        onSearchClientGame={(e) => setSearchClient(e)}
        setSearchPaymentStatus={(data) => setSearchPaymentStatus(data)}
        setSearchStatus={(data) => setSearchStatus(data)}
        gameData={gameData === undefined ? [] : gameData}
        clientData={clientData === undefined ? [] : clientData}
      />
      {!loading && userData?.orders === undefined && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {products.length > 0 && (
        <TableOrders
          handleCurrentPage={handleCurrentPage}
          handleCurrentDelete={fetchDataAll}
          pageCount={pageCounts}
          product={products}
          currentPage={currentPage}
          userId={data}
          gamesData={gameData === undefined ? [] : gameData}
          walletsData={walletsData === undefined ? [] : walletsData}
          danhsachbanData={
            danhsachbanDatas === undefined ? [] : danhsachbanDatas
          }
        />
      )}
    </div>
  );
};

export default index;
