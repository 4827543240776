import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import UploadMultiple from "components/Mui/Uploads/UploadMultiple";
import { Field, Form, Formik } from "formik";
import AutocompleteId from "hooks/useAutocomplete";
import React from "react";
import axiosusers from "utils/api/axiosusers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import useUserId from "hooks/useUserId";
import MenuCheckBox from "components/Mui/MenuCheckBox/MenuCheckBox";
import Images from "components/Avatar/Images";
import { FormAutocomplete } from "hooks/FormAutocomplete";

const AddOrders = (props) => {
  const { gamesData, walletsData, clientsData, danhsachbanData, id } = props;
  // eslint-disable-next-line no-undef
  const URL = process.env.REACT_APP_NOT_SECRET_CODE;

  const navigate = useNavigate();
  const { userId } = useUserId();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [done, setDone] = React.useState(false);
  const [avatar, setAvatar] = React.useState([]);
  const [gamesSearch, setGamesSearch] = React.useState([]);
  const [productsSome, setProductsSome] = React.useState([]);
  const [loadingMenu, setLoadingMenu] = React.useState(false);

  const [inputClients, setInputClients] = React.useState([]);
  const [budgetsJson, setBudgetsJson] = React.useState([]);

  const [salePrice, setSalePrice] = React.useState(0);
  const [menu, setMenu] = React.useState([]);
  const [fieldValueWallet, setFieldValueWallet] = React.useState([]);
  const [valueWallet, setValueWallet] = React.useState([]);

  const setChangeWallets = (values) => {
    const valueIds = values.map((item) => item.id);

    const updatedValueWallet = values.map((item) => ({
      ...item,
      budgets:
        valueWallet.find((field) => field.id === item.id)?.budgets ||
        item.budgets,
    }));
    const walletsDataFiltered = valueWallet
      .filter((item) => !valueIds.includes(item.id))
      .map((item) => ({
        ...item,
        budgets: item.budgets.map((budget) => ({ ...budget, usedQuantity: 0 })),
      }));

    const newWalletData = [...updatedValueWallet, ...walletsDataFiltered];

    setValueWallet(newWalletData);
    onCheckedChangeBudgets(updatedValueWallet);
    setFieldValueWallet(updatedValueWallet);
  };

  const handleChangeInput = (id, event) => {
    const { name, value } = event.target;

    const newInputFields = fieldValueWallet.map((item) => ({
      ...item,
      budgets: item.budgets.map((budget) =>
        budget.id === id ? { ...budget, [name]: value } : budget
      ),
    }));

    const updatedValueWallet = valueWallet.map((item) => ({
      ...item,
      budgets:
        newInputFields.find((field) => field.id === item.id)?.budgets ||
        item.budgets,
    }));

    setValueWallet(updatedValueWallet);
    onCheckedChangeBudgets(updatedValueWallet);
    setFieldValueWallet(newInputFields);
  };

  React.useEffect(() => {
    setProductsSome(gamesData);
  }, [gamesData]);

  const initialValues = {
    price: "",
    notes: "",
    nameGame: "",
    bonusAmount: "",
  };
  const validationSchema = Yup.object().shape({});

  const handleChangeGames = (value) => {
    const productIds = value.map((item) => item.id);
    const walletsDataFiltered = walletsData.filter((item) =>
      productIds.includes(item.productId)
    );
    const updatedWalletsData = walletsDataFiltered.map((game) => ({
      ...game,
      budgets:
        valueWallet.find((wallet) => wallet.id === game.id)?.budgets ||
        game.budgets,
    }));
    setValueWallet(updatedWalletsData);
    setGamesSearch(value);
  };

  const handPhotoChange = (avatar) => {
    setAvatar(avatar);
  };

  const dataProduct = ["Coffee", "Playstation", "Fastfood", "Cake"];
  const handleChangeMenu = (value) => {
    const gamesDataSone = gamesData.filter((item) =>
      value.includes(item.status)
    );
    setMenu(value);
    setProductsSome(gamesDataSone);
    setLoadingMenu(true);
    setTimeout(() => {
      setLoadingMenu(false);
    }, 10);
  };
  const onCheckedChangeBudgets = (value) => {
    const updatedBudgets = value.flatMap((item) => item.budgets);
    const totalSaple = updatedBudgets.reduce((total, budget) => {
      return total + Number(budget.price) * Number(budget.usedQuantity);
    }, 0);
    setSalePrice(totalSaple);
    setBudgetsJson(value);
  };
  const handleChangeClients = (value) => {
    setInputClients(value);
  };
  const onSubmit = async (values) => {
    setDone(true);
    setErrorMessage("");
    let errorMessage = "";
    avatar.map((item) => {
      if (item.file.size > 2 * 1024 * 1024) {
        errorMessage = "size must be less than 2MB";
      } else if (
        item.file.type !== "image/png" &&
        item.file.type !== "image/jpg" &&
        item.file.type !== "image/jpeg"
      ) {
        errorMessage = "file type must be PNG or JPG or JPEG";
      }
    });

    if (inputClients?.id === undefined) {
      errorMessage = "Vui lòng chọn khách hàng";
    }
    if (errorMessage) {
      setDone(false);
      return setErrorMessage(errorMessage);
    }
    let totalUsedAmount = 0;
    let totalRemainAmount = 0;

    let paymentStatus = "";
    if (totalRemainAmount == 0) {
      paymentStatus = "unPaid";
    } else if (salePrice <= totalRemainAmount) {
      paymentStatus = "paid";
    } else {
      paymentStatus = "partPaid";
    }
    const data = {
      userId: userId?.id,
      clientId: inputClients?.id,
      ordersProducts: gamesSearch,
      may: danhsachbanData.filter((item) => item.id === Number(id)),
      status: "inProgress",
      notes: values.notes,
      salePrice,
      basePrice: 0,
      bonusAmount: values.bonusAmount,
      paidAmount: totalUsedAmount,
      paymentStatus,
      budgetsJson,
      transactionsJson: [],
    };

    try {
      const response = await axiosusers.post(`/orders/create-orders`, data);

      if (response.status === 200) {
        console.log(response);
        toast.success(response.data.message, {
          position: "top-right",
        });
        setTimeout(function () {
          navigate("/manager/donhang");
        }, 500);
        let namePhoto = "";

        let count = 0;
        for (const image of avatar.sort((a, b) => a.size - b.size)) {
          const formData = new FormData();
          formData.append("file", image.file);
          formData.append("orderId", response.data.orderId);
          formData.append("status", "image");

          try {
            await axiosusers.post(`/orders/ordersphotos`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });

            count += 1;
            namePhoto += `\n ${URL}img/${response.data.image}`;
          } catch (error) {
            count += 1;
            console.log(error);
          }
        }
        if (count === avatar.length) {
          const dataMessages = {
            message: `${response.data.text}${namePhoto}`,
            orderId: response.data.orderId,
          };
          await axiosusers.post(`/telegramApi/api-orders`, dataMessages);
        }
      }
    } catch (error) {
      setDone(false);
      console.log(error);
      toast.error("Update error", {
        position: "top-right",
      });
    }
  };
  const danhsachban = danhsachbanData.find((item) => item.id === Number(id));
  return (
    <Box sx={{ marginTop: "1rem" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={done ? null : onSubmit}
      >
        {() => (
          <Form autoComplete="off">
            <Box>
              <Typography variant="h6" component="div" sx={{ fontWeight: 500 }}>
                {`${
                  danhsachban?.selectProduct === "Playstation"
                    ? "Ngồi máy số "
                    : "Ngồi bàn số "
                }${danhsachban?.orderNumber}`}
              </Typography>
            </Box>
            <MenuCheckBox
              data={dataProduct}
              name="Chọn sản phẩm"
              handleChangeMenu={handleChangeMenu}
            />

            {!loadingMenu && (
              <AutocompleteId
                multiple={true}
                data={productsSome}
                placeholder="Chọn một hoặc nhiều"
                label="Sản phẩm"
                id="Sản phẩm"
                name="Sản phẩm"
                size="small"
                openError={!menu.includes("Playstation")}
                done={
                  errorMessage &&
                  !menu.includes("Playstation") &&
                  gamesSearch.length === 0
                }
                onCheckedChange={handleChangeGames}
                optionLabel={(label) => label.name}
              />
            )}
            {valueWallet.length > 0 && (
              <FormAutocomplete
                placeholder="Chọn một hoặc nhiều"
                onChange={(event, values) => {
                  setChangeWallets(values);
                }}
                multiple={true}
                filterSelectedOptions={true}
                style={{ marginTop: `0.5rem` }}
                name="Thực đơn"
                label="Thực đơn"
                options={valueWallet}
              />
            )}
            {fieldValueWallet.map((inputField) => (
              <Box
                key={inputField.id}
                component="form"
                autoComplete="off"
                sx={{ marginTop: "0.5rem" }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 500, fontSize: "1rem" }}
                >
                  Tên thực đơn: {inputField.name}
                </Typography>
                <Box
                  component="form"
                  autoComplete="off"
                  sx={{ display: "flex", alignItems: "center", gap: 1, mb: 0 }}
                >
                  <Typography variant="subtitle1" sx={{ flex: 1 }}>
                    Tên ngân sách
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ flex: 0.5, padding: "0px 10px" }}
                  >
                    Ảnh
                  </Typography>
                  <Typography variant="subtitle1" sx={{ flex: 1 }}>
                    Giá mặc định
                  </Typography>
                  <Typography variant="subtitle1" sx={{ flex: 1 }}>
                    Nhập số lượng
                  </Typography>
                </Box>
                {inputField.budgets.map((budget) => (
                  <Box
                    key={budget.id}
                    component="form"
                    autoComplete="off"
                    sx={{ display: "flex", gap: 1, mb: 1, marginTop: 1 }}
                  >
                    <Typography variant="h6" sx={{ fontSize: "1rem", flex: 1 }}>
                      {budget.name}
                    </Typography>
                    <Box sx={{ flex: 0.5 }}>
                      <Images nameImage={inputField.product.image} />
                    </Box>
                    <TextField
                      fullWidth
                      type="number"
                      name="price"
                      value={budget.price}
                      onChange={(event) => handleChangeInput(budget.id, event)}
                      label="Nhập số giá"
                      variant="outlined"
                      size="small"
                      sx={{ flex: 1 }}
                    />
                    <TextField
                      fullWidth
                      type="number"
                      name="usedQuantity"
                      value={budget.usedQuantity}
                      onChange={(event) => handleChangeInput(budget.id, event)}
                      label="Nhập số lượng"
                      variant="outlined"
                      size="small"
                      sx={{ flex: 1 }}
                    />
                  </Box>
                ))}
              </Box>
            ))}
            <AutocompleteId
              data={clientsData}
              label="Khách hàng"
              id="Khách hàng"
              name="Khách hàng"
              size="small"
              openError={true}
              done={errorMessage && inputClients?.id === undefined}
              onCheckedChange={handleChangeClients}
              optionLabel={(label) => label.fullName}
            />

            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              type="number"
              name="bonusAmount"
              label="Thưởng"
              variant="outlined"
              size="small"
              fullWidth
            />

            <Field
              sx={{ marginTop: `0.5rem` }}
              as={TextField}
              multiline
              minRows="4"
              name="notes"
              label="Ghi chú"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Box sx={{ marginTop: `1rem` }}>
              <UploadMultiple handPhotoChange={handPhotoChange} />
            </Box>

            {errorMessage && (
              <Alert sx={{ marginTop: `0.5rem` }} severity="error">
                {errorMessage}
              </Alert>
            )}
            <Button
              type="submit"
              sx={{ marginTop: `2rem` }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={done}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>
      <ToastContainer
        style={{
          top: "10px",
          right: "0",
          width: "max-content",
        }}
      />
    </Box>
  );
};

export default AddOrders;
